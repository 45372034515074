import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useSearchParams, createSearchParams } from "react-router-dom";
import {
    Button, TextField, Typography, Link, Box, Backdrop,
    Stack, CircularProgress, Collapse
} from '@mui/material';
import ReCAPTCHA from 'reaptcha';
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import {
    LoginMfaCodeModel, ApiViewEntityResponse, LoginStageResultModel,
    ApiViewResponseError, LoginMfaCodeResultModel, LoginStage
} from '../../interfaces';
import { createMfaCode, verifyMfaCode, logoutUser, spaSessionExpired, initError, spaResetErrorMessages } from "../../app/loginSlice"
import ErrorsBox from '../errorsBox';
import { parseReturnUrl } from '../../functions';
import { GOOGLE_RECAPTCHA_SITEKEY } from "../../constants";

export default function Mfa() {
    const [searchParams] = useSearchParams();
    const [mfaCode, setMfaCode] = useState('');
    const [mfaCodeError, setMfaCodeError] = useState('');
    const [reCaptchaToken, setReCaptchaToken] = useState<string | null>(null);
    const [reCaptchaVisible, setReCaptchaVisible] = useState<boolean>(false);
    const loginState = useAppSelector(store => store.login);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const captchaRef = useRef<ReCAPTCHA | null>(null);

    useEffect(() => {
        if (loginState?.userData == null ||
            loginState?.loginData == null ||
            loginState?.mfaMethodData == null) {
            window.location.href = '/' + window.location.search;
        }
    }, []);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        let hasError: boolean = false;
        if (mfaCode.length == 0) {
            setMfaCodeError('MFA Code could not be empty.');
            hasError = true;
        }
        else {
            setMfaCodeError('');
        }
        if (loginState.reCaptchaEnabled) {
            if (reCaptchaVisible && reCaptchaToken == null) {
                dispatch(initError("Please solve reCAPTCHA puzzle."));
                hasError = true;
            }
            else {
                dispatch(spaResetErrorMessages());
            }
        }
        if (hasError) {
            return;
        }

        const returnUrl = parseReturnUrl(searchParams.get("returnUrl"));
        const loginMfaCode: LoginMfaCodeModel = {
            method: loginState.mfaMethodData!.method,
            code: mfaCode,
            returnUrl: returnUrl,
            reCaptchaToken: reCaptchaToken
        };
        const dispatchResult = await dispatch(verifyMfaCode(loginMfaCode));
        if (dispatchResult.meta.requestStatus == "fulfilled") {
            const mfaResultData = dispatchResult.payload as ApiViewEntityResponse<LoginStageResultModel<LoginMfaCodeResultModel>>;
            if (mfaResultData.data.stage == LoginStage.Authenticated) {
                navigate({
                    pathname: '/client',
                    search: `?${createSearchParams({ returnUrl: parseReturnUrl(searchParams.get("returnUrl")) })}`
                });
            }
            else if (mfaResultData.data.stage == LoginStage.ForcePasswordReset) {
                navigate({
                    pathname: '/password/change',
                    search: `?${createSearchParams({ returnUrl: parseReturnUrl(searchParams.get("returnUrl")) })}`
                });
            }
            else if (mfaResultData.data.stage == LoginStage.FillUserProfile) {
                navigate({
                    pathname: '/signin/profile',
                    search: `?${createSearchParams({ returnUrl: parseReturnUrl(searchParams.get("returnUrl")) })}`
                });
            }
            else {
                showReCaptchaWidget();
            }
        }
        else if (dispatchResult.meta.requestStatus == "rejected") {
            const errors = dispatchResult.payload as ApiViewResponseError[];
            if (errors?.some(e => e.code == "UNAUTHORIZED")) {
                dispatch(spaSessionExpired());
                navigate({
                    pathname: '/',
                    search: `?${createSearchParams({ returnUrl: parseReturnUrl(searchParams.get("returnUrl")) })}`
                });
            }
            else {
                showReCaptchaWidget();
            }
        }
    };

    const showReCaptchaWidget = () => {
        if (loginState.reCaptchaEnabled) {
            captchaRef.current?.reset();
            setReCaptchaVisible(true);
            setReCaptchaToken(null);
        }
    };

    const handleResendCode = async () => {
        const dispatchResult = await dispatch(createMfaCode(loginState.mfaMethodData!.method));
        if (dispatchResult.meta.requestStatus == "rejected") {
            const errors = dispatchResult.payload as ApiViewResponseError[];
            if (errors?.some(e => e.code == "UNAUTHORIZED")) {
                dispatch(spaSessionExpired());
                navigate({
                    pathname: '/',
                    search: `?${createSearchParams({ returnUrl: parseReturnUrl(searchParams.get("returnUrl")) })}`
                });
            }
        }
    };

    const handleSwitchMethod = () => {
        navigate({
            pathname: '/mfa/providers',
            search: `?${createSearchParams({ returnUrl: parseReturnUrl(searchParams.get("returnUrl")) })}`
        });
    };

    const handleCancel = async () => {
        const dispatchResult = await dispatch(logoutUser());
        if (dispatchResult.meta.requestStatus == "fulfilled") {
            navigate({
                pathname: '/',
                search: `?${createSearchParams({ returnUrl: parseReturnUrl(searchParams.get("returnUrl")) })}`
            });
        }
    };

    return (
        <React.Fragment>
            <Box component="form" onSubmit={handleSubmit} width={1} noValidate>
                <Stack spacing={1.5}>
                    <Typography variant="h4" color="primary">Log in to your account</Typography>
                    <Typography variant="body1">
                        Enter authentication code sent to {loginState.mfaMethodData?.contact}
                    </Typography>
                    <Typography variant='body1'>
                        <Link id="mfa-resendcode-link" href="#" onClick={handleResendCode}>Resend Code</Link>
                        {(loginState.loginData?.mfaMethods.length ?? 0) > 1 &&
                            <React.Fragment>
                                <Box component="span" color="text.secondary" fontSize={"0.7rem"} p={'2rem'}>OR</Box>
                                <Link id="mfa-switchmethod-link" href="#" onClick={handleSwitchMethod}>Switch Method</Link>
                            </React.Fragment>
                        }
                    </Typography>
                    <Box>
                        <ErrorsBox errors={loginState.messages} />
                        <TextField
                            inputProps={{ style: { height: 12 } }}
                            margin="normal" id="mfaCode" name="mfaCode" type="password" label="Enter MFA Code"
                            onChange={e => { setMfaCode(e.target.value) }}
                            error={mfaCodeError.length > 0}
                            helperText={mfaCodeError}
                            required fullWidth autoFocus />
                        {loginState.reCaptchaEnabled &&
                        <Collapse in={reCaptchaVisible}>
                            <Box display="flex" flexDirection="row" justifyContent="center" pl="1px" pt={1}>
                                <ReCAPTCHA sitekey={GOOGLE_RECAPTCHA_SITEKEY}
                                    onVerify={(token) => { setReCaptchaToken(token); }}
                                    onExpire={() => { setReCaptchaToken(null); }}
                                    ref={captchaRef} hl="en" />
                            </Box>
                        </Collapse>
                        }
                        <Button
                            id="mfacode-continue-button"
                            type="submit" fullWidth variant="contained" sx={{ mt: 2 }}
                        >
                            Continue
                        </Button>
                    </Box>
                    <Typography variant="body1" pt={1}>
                        <Link id="mfa-cancel-link" href="#" onClick={handleCancel}>Cancel</Link>
                    </Typography>
                </Stack>
            </Box>
            <Backdrop open={loginState.loading} className="loading">
                <CircularProgress />
            </Backdrop>
        </React.Fragment>
    );
}
