import {
    createSlice, createAsyncThunk, isRejectedWithValue, isPending, PayloadAction
} from '@reduxjs/toolkit';
import {
    ApiViewResponse, ApiViewEntityResponse, ApiViewResponseError,
    LoginStageResultModel, LoginUserCredentialsModel, LoginResultModel,
    LoginUserDataModel, Message, MessageLevel, LoginMfaCodeModel,
    MfaMethodModel, MfaMethod, LoginContextModel,
    LoginMfaCodeResultModel, LoginStage, UserPasswordResetTokenRequestModel,
    ChangePasswordRequestModel, LoginUserNameOrEmailModel, ApplicationModel,
    LoginSigninProfileModel
} from '../interfaces';
import identityService from '../services/identityService';
import { getBodyErrors } from '../services/apiService';

interface LoginState {
    loading: boolean,
    returnUrl: string | null,
    reCaptchaEnabled: boolean,
    loginStage: LoginStage,
    userData: LoginUserDataModel | null,
    loginData: LoginResultModel | null,
    mfaMethodData: MfaMethodModel | null,
    userApplications: ApplicationModel[],
    userApplication: ApplicationModel | null,
    password: string | null,
    error: string | null,
    messages: Message[]
}

const initialState: LoginState = {
    loading: false,
    returnUrl: null,
    reCaptchaEnabled: true,
    loginStage: LoginStage.Initialization,
    userData: null,
    loginData: null,
    mfaMethodData: null,
    userApplications: [],
    userApplication: null,
    password: null,
    error: null,
    messages: []
}

const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        initError: (state, action) => {
            state.error = action.payload;
            state.messages = [{ details: action.payload, level: MessageLevel.Error }];
        },
        initPassword: (state, action) => {
            state.password = action.payload;
        },
        spaReset: (state) => {
            state.loginStage = LoginStage.User;
            state.loading = initialState.loading;
            state.userData = initialState.userData;
            state.password = initialState.password;
            state.error = initialState.error;
            state.messages = initialState.messages;
        },
        spaResetErrorMessages: (state) => {
            state.error = initialState.error;
            state.messages = initialState.messages;
        },
        spaSessionExpired: (state) => {
            state.loginStage = LoginStage.User;
            state.loading = initialState.loading;
            state.userData = initialState.userData;
            state.password = initialState.password;
            state.error = initialState.error;
            state.messages = [{ details: 'Your session timed out, please sign in again.', level: MessageLevel.Error }];
        },
        passwordResetInit: (state) => {
            state.loginStage = LoginStage.PasswordReset;
            state.password = initialState.password;
            state.error = initialState.error;
            state.messages = initialState.messages;
        },
        userApplicationSelected: (state, action: PayloadAction<ApplicationModel>) => {
            state.userApplication = action.payload;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(loginInit.fulfilled, (state, action) => {
                state.loginStage = action.payload.data.stage;
                state.loading = false;
                state.returnUrl = action.meta.arg;
                state.reCaptchaEnabled = action.payload.data.metadata.reCaptchaEnabled;
                state.userApplication = action.payload.data.metadata.client;
                state.userData = action.payload.data.metadata.user;
                state.loginData = action.payload.data.metadata.login;
            })
            .addCase(findUser.fulfilled, (state, action) => {
                state.loginStage = action.payload.data.stage;
                state.loading = false;
                state.userData = action.payload.data.metadata;
                state.messages = [];
            })
            .addCase(loginUser.fulfilled, (state, action) => {
                state.loginStage = action.payload.data.stage;
                state.loading = false;
                state.loginData = action.payload.data.metadata;
                state.messages = [];
            })
            .addCase(createMfaCode.fulfilled, (state, action) => {
                state.loginStage = action.payload.data.stage;
                state.loading = false;
                state.mfaMethodData = action.payload.data.metadata;
                state.messages = [];
            })
            .addCase(verifyMfaCode.fulfilled, (state, action) => {
                if (action.payload.data.stage == LoginStage.Mfa) {
                    state.messages = [{ details: action.payload.data.metadata.message, level: MessageLevel.Error }];
                }
                else {
                    state.messages = [];
                }
                state.loginStage = action.payload.data.stage;
                state.loading = false;
            })
            .addCase(logoutUser.fulfilled, (state) => {
                state.loading = false;
                state.loginStage = LoginStage.User;
                state.userData = null;
                state.loginData = null;
                state.mfaMethodData = null;
                state.messages = [];
            })
            .addCase(getUserApplications.fulfilled, (state, action) => {
                state.loading = false;
                state.loginStage = action.payload.data.stage;
                state.userApplications = action.payload.data.metadata;
                state.messages = [];
            })
            .addCase(sendPasswordResetLink.fulfilled, (state, action) => {
                state.loading = false;
                state.loginStage = action.payload.data.stage;
                state.messages = [];
            })
            .addCase(resetUserPassword.rejected, (state, action) => {
                state.loading = false;
                if (action.payload?.some(e => e.detail == "Invalid token.")) {
                    state.messages = [{ details: "Invalid Token and/or Outdated Token", level: MessageLevel.Error }];
                }
                else {
                    state.messages = action.payload!.map(e => ({ details: e.detail, level: MessageLevel.Error }));
                }
            })
            .addCase(resetUserPassword.fulfilled, (state) => {
                state.loading = false;
                state.messages = [];
            })
            .addCase(changeUserPassword.rejected, (state, action) => {
                state.loading = false;
                if (action.payload?.some(e => e.detail == "Incorrect password.")) {
                    state.messages = [{ details: "Incorrect current password.", level: MessageLevel.Error }];
                }
                else {
                    state.messages = action.payload!.map(e => ({ details: e.detail, level: MessageLevel.Error }));
                }
            })
            .addCase(changeUserPassword.fulfilled, (state, action) => {
                state.loading = false;
                state.loginStage = action.payload.data.stage;
                state.messages = [];
            })
            .addCase(updateUserProfile.fulfilled, (state, action) => {
                state.loading = false;
                state.loginStage = action.payload.data.stage;
                state.messages = [];
            })
            .addMatcher(isApiPending, (state) => {
                state.loading = true;
            })
            .addMatcher(isApiRejected, (state, action) => {
                state.loading = false;
                if (action.payload) {
                    state.messages = action.payload.map(e => ({ details: e.detail, level: MessageLevel.Error }));
                }
            })
    }
});

export const loginInit = createAsyncThunk<ApiViewEntityResponse<LoginStageResultModel<LoginContextModel>>, string, { rejectValue: ApiViewResponseError[] }>
    ('login/init', async (returnUrl: string, { rejectWithValue }) => {
        try {
            const response = await identityService.loginInit(returnUrl);
            return response;
        } catch (err: any) {
            return rejectWithValue(await getBodyErrors(err));
        }
    });

export const findUser = createAsyncThunk<ApiViewEntityResponse<LoginStageResultModel<LoginUserDataModel>>, string, { rejectValue: ApiViewResponseError[] }>
    ('user/find', async (userNameOrEmail: string, { rejectWithValue }) => {
        try {
            const response = await identityService.findUser(userNameOrEmail);
            return response;
        } catch (err: any) {
            return rejectWithValue(await getBodyErrors(err));
        }
    });

export const loginUser = createAsyncThunk<ApiViewEntityResponse<LoginStageResultModel<LoginResultModel>>, LoginUserCredentialsModel, { rejectValue: ApiViewResponseError[] }>
    ('user/login', async (body: LoginUserCredentialsModel, { rejectWithValue }) => {
        try {
            const response = await identityService.loginUser(body);
            return response;
        } catch (err: any) {
            return rejectWithValue(await getBodyErrors(err));
        }
    });

export const createMfaCode = createAsyncThunk<ApiViewEntityResponse<LoginStageResultModel<MfaMethodModel>>, MfaMethod, { rejectValue: ApiViewResponseError[] }>
    ('mfa/code/create', async (mfaMethod: MfaMethod, { rejectWithValue }) => {
        try {
            const response = await identityService.createMfaCode(mfaMethod);
            return response;
        } catch (err: any) {
            return rejectWithValue(await getBodyErrors(err));
        }
    });

export const verifyMfaCode = createAsyncThunk<ApiViewEntityResponse<LoginStageResultModel<LoginMfaCodeResultModel>>, LoginMfaCodeModel, { rejectValue: ApiViewResponseError[] }>
    ('mfa/code/verify', async (body: LoginMfaCodeModel, { rejectWithValue }) => {
        try {
            const response = await identityService.verifyMfaCode(body);
            return response;
        } catch (err: any) {
            return rejectWithValue(await getBodyErrors(err));
        }
    });

export const logoutUser = createAsyncThunk<ApiViewResponse, undefined, { rejectValue: ApiViewResponseError[] }>
    ('user/logout', async (_, { rejectWithValue }) => {
        try {
            const response = await identityService.logoutUser();
            return response;
        } catch (err: any) {
            return rejectWithValue(await getBodyErrors(err));
        }
    });

export const sendPasswordResetLink = createAsyncThunk<ApiViewEntityResponse<LoginStageResultModel<object>>, LoginUserNameOrEmailModel, { rejectValue: ApiViewResponseError[] }>
    ('passwordReset/link/send', async (body: LoginUserNameOrEmailModel, { rejectWithValue }) => {
        try {
            const response = await identityService.sendPasswordResetLink(body);
            return response;
        } catch (err: any) {
            return rejectWithValue(await getBodyErrors(err));
        }
    });
export const resetUserPassword = createAsyncThunk<ApiViewResponse, ResetUserPasswordModel, { rejectValue: ApiViewResponseError[] }>
    ('password/reset', async (model: ResetUserPasswordModel, { rejectWithValue }) => {
        try {
            const response = await identityService.resetUserPassword(model.externalId, model.body);
            return response;
        } catch (err: any) {
            return rejectWithValue(await getBodyErrors(err));
        }
    });
export const changeUserPassword = createAsyncThunk<ApiViewEntityResponse<LoginStageResultModel<LoginResultModel>>, ChangePasswordRequestModel, { rejectValue: ApiViewResponseError[] }>
    ('password/change', async (body: ChangePasswordRequestModel, { rejectWithValue }) => {
        try {
            const response = await identityService.changeUserPassword(body);
            return response;
        } catch (err: any) {
            return rejectWithValue(await getBodyErrors(err));
        }
    });

export const updateUserProfile = createAsyncThunk<ApiViewEntityResponse<LoginStageResultModel<LoginResultModel>>, LoginSigninProfileModel, { rejectValue: ApiViewResponseError[] }>
    ('signin/profile/update', async (body: LoginSigninProfileModel, { rejectWithValue }) => {
        try {
            const response = await identityService.updateSigninProfile(body);
            return response;
        } catch (err: any) {
            return rejectWithValue(await getBodyErrors(err));
        }
    });

export const getUserApplications = createAsyncThunk<ApiViewEntityResponse<LoginStageResultModel<ApplicationModel[]>>, undefined, { rejectValue: ApiViewResponseError[] }>
    ('user/applications', async (_, { rejectWithValue }) => {
        try {
            const response = await identityService.getUserApplications();
            return response;
        } catch (err: any) {
            return rejectWithValue(await getBodyErrors(err));
        }
    });

const isApiPending = isPending(
    loginInit, findUser, loginUser, createMfaCode,
    verifyMfaCode, logoutUser, sendPasswordResetLink,
    resetUserPassword, changeUserPassword, updateUserProfile,
    getUserApplications);
const isApiRejected = isRejectedWithValue(
    loginInit, findUser, loginUser, createMfaCode,
    verifyMfaCode, logoutUser, sendPasswordResetLink,
    updateUserProfile, getUserApplications);

export const {
    initError, initPassword, spaReset, spaResetErrorMessages,
    spaSessionExpired, passwordResetInit, userApplicationSelected
} = loginSlice.actions

export default loginSlice.reducer

export interface ResetUserPasswordModel {
    externalId: string,
    body: UserPasswordResetTokenRequestModel
}
