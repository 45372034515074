import React, { useEffect } from 'react';
import { useNavigate, useSearchParams, createSearchParams } from "react-router-dom";
import { Typography, Link, Box, Stack, Backdrop, CircularProgress } from '@mui/material';
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { parseReturnUrl } from '../../functions';
import { getUserApplications, userApplicationSelected, logoutUser } from '../../app/loginSlice';
import { ApplicationModel } from '../../interfaces';

export default function Client() {
    const [searchParams] = useSearchParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const loginState = useAppSelector(store => store.login);

    useEffect(() => {
        const redirectUrl: string = parseReturnUrl(searchParams.get("returnUrl"));
        if (redirectUrl) {
            navigate({
                pathname: '/client/redirect',
                search: `?${createSearchParams({ returnUrl: redirectUrl })}`
            });
        }
        else {
            dispatch(getUserApplications());
        }
    }, []);

    const handleApplicationClick = (application: ApplicationModel) => {
        dispatch(userApplicationSelected(application));
        navigate({
            pathname: '/client/redirect',
            search: `?${createSearchParams({ returnUrl: application.clientUri })}`
        });
    };

    const handleSignOut = async () => {
        const dispatchResult = await dispatch(logoutUser());
        if (dispatchResult.meta.requestStatus == "fulfilled") {
            navigate({
                pathname: '/',
                search: `?${createSearchParams({ returnUrl: parseReturnUrl(searchParams.get("returnUrl")) })}`
            });
        }
    };

    return (
        <React.Fragment>
            <Box width={1}>
                <Stack spacing={4}>
                    <Box>
                        <Typography variant="h4" color="primary" mb={1.5}>You are logged in</Typography>
                        <Typography variant="body1">Pick the application to sign in</Typography>
                    </Box>
                    {loginState.userApplications.map((d, i) => (
                        <Link id={`${ d.clientId.toLowerCase() }-application-link`} href="#" onClick={() => { handleApplicationClick(d); }} key={i}>
                            <Box component='img' src={d.logoUri} alt={d.description} maxWidth={180} />
                        </Link>
                    ))}
                    <Typography variant="body1" pt={1}>
                        <Link id="application-selection-signout-link" href="#" onClick={handleSignOut}>Sign Out</Link>
                    </Typography>
                </Stack>
            </Box>
            <Backdrop open={loginState.loading} className="loading">
                <CircularProgress />
            </Backdrop>
        </React.Fragment>
    );
}
