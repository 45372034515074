export enum MessageLevel {
    Information,
    Warning,
    Error
}

export enum LoginStage {
    Initialization = 0,
    User = 10,
    SignIn = 20,
    Mfa = 30,
    Authenticated = 40,
    PasswordReset = 50,
    ResetLinkSent = 60,
    ForcePasswordReset = 70,
    FillUserProfile = 80
}

export enum MfaMethod {
    Email = 1,
    Phone = 2
}

export enum LoginProvider {
    Password = 0,
    Microsoft = 1
}

export interface Message {
    details: string,
    level: MessageLevel
}

export interface ApiViewResponseError {
    id: string,
    code: string,
    status: string,
    detail: string,
    title: string
}

export interface ApiViewResponseResource {
    id: string,
    type: string,
    data: string | number | symbol | object
}

export interface ApiViewResponse {
    resource: ApiViewResponseResource[],
    errors: ApiViewResponseError[],
    id: string
}

export interface ApiViewEntityResponse<T> extends ApiViewResponse {
    data: T
}

export interface LoginStageResultModel<T> {
    stage: LoginStage,
    metadata: T
}

export interface LoginReturnUrlModel {
    returnUrl: string
}

export interface LoginContextModel {
    client: ApplicationModel,
    login: LoginResultModel,
    user: LoginUserDataModel,
    reCaptchaEnabled: boolean
}

export interface LoginUserNameOrEmailModel {
    userNameOrEmail: string,
    reCaptchaToken: string | null
}

export interface LoginUserDataModel {
    userNameOrEmail: string,
    providers: LoginProvider[],
    isAuthenticated: boolean
}

export interface MfaMethodModel {
    method: MfaMethod,
    contact: string
}

export interface LoginResultModel {
    mfaMethods: MfaMethodModel[]
}

export interface LoginUserCredentialsModel extends LoginUserNameOrEmailModel {
    password: string,
    returnUrl: string
}

export interface LoginMfaCodeModel {
    method: MfaMethod,
    code: string,
    returnUrl: string,
    reCaptchaToken: string | null
}

export interface LoginMfaCodeResultModel {
    message: string
}

export interface UserPasswordModel {
    password: string,
    confirmPassword: string
}

export interface UserPasswordResetTokenRequestModel extends UserPasswordModel {
    resetToken: string
}

export interface NewPasswordRequestModel extends UserPasswordModel {
    currentPassword: string
}

export interface ChangePasswordRequestModel extends NewPasswordRequestModel {
    returnUrl: string
}

export interface LoginSigninProfileModel {
    email: string,
    returnUrl: string
}

export interface ApplicationModel {
    id: number,
    clientId: string,
    clientName: string,
    description: string,
    clientUri: string,
    logoUri: string
}
