import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams, createSearchParams } from "react-router-dom";
import {
    Button, TextField, Typography, Box, Backdrop, Stack, CircularProgress, Link
} from '@mui/material';
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import {
    resetUserPassword, spaReset, ResetUserPasswordModel, loginInit, initError, spaResetErrorMessages
} from "../../app/loginSlice"
import ErrorsBox from '../errorsBox';
import PasswordRequirements, {
    PasswordValidationResult, ValidatePassword, IsPasswordValid
} from '../passwordRequirements';
import { parseReturnUrl } from '../../functions';
import { LoginStage } from '../../interfaces';

export default function PasswordReset() {
    const [searchParams] = useSearchParams();
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [passwordValidity, setPasswordValidity] = useState<PasswordValidationResult>({
        lengthGt8: false,
        upperCaseChar: false,
        lowerCaseChar: false,
        digit: false,
        specialChar: false,
        matchConfirmPassword: false
    });
    const loginState = useAppSelector(store => store.login);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (loginState?.loginStage == LoginStage.Initialization) {
            dispatch(loginInit(parseReturnUrl(searchParams.get("returnUrl"))));
        }
    }, []);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!IsPasswordValid(password, passwordConfirmation)) {
            dispatch(initError("Please follow the requirements above."));
            return;
        }
        else {
            dispatch(spaResetErrorMessages());
        }

        const model: ResetUserPasswordModel = {
            externalId: searchParams.get("id") as string,
            body: {
                resetToken: searchParams.get("token") as string,
                password: password,
                confirmPassword: passwordConfirmation
            }
        };
        const dispatchResult = await dispatch(resetUserPassword(model));
        if (dispatchResult.meta.requestStatus == "fulfilled") {
            navigate('/password/reset/done');
        }
    };

    const handleReturnToLogin = () => {
        dispatch(spaReset());
        navigate({
            pathname: '/',
            search: `?${createSearchParams({ returnUrl: parseReturnUrl(searchParams.get("returnUrl")) })}`
        });
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
        const validationResult = ValidatePassword(e.target.value, passwordConfirmation);
        setPasswordValidity(validationResult);
    };

    const handlePasswordConfirmationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPasswordConfirmation(e.target.value);
        const validationResult = ValidatePassword(password, e.target.value);
        setPasswordValidity(validationResult);
    };

    return (
        <React.Fragment>
            <Box component="form" onSubmit={handleSubmit} width={1} noValidate>
                <Stack spacing={1.5}>
                    <Typography variant="h4" color="primary">Reset your account password</Typography>
                    <Box>
                        <Typography variant="subtitle1">Your password must:</Typography>
                        <PasswordRequirements valid={passwordValidity} />
                        <ErrorsBox errors={loginState.messages} />
                        <TextField
                            inputProps={{ style: { height: 12 } }}
                            margin="normal" id="password" name="password" type="password"
                            label="Enter new password"
                            onChange={handlePasswordChange}
                            required fullWidth autoFocus />
                        <TextField
                            sx={{ mt: 0.5 }} inputProps={{ style: { height: 12 } }}
                            margin='dense' id="passwordConfirmation" name="passwordConfirmation" type="password"
                            label="Confirm new password"
                            onChange={handlePasswordConfirmationChange}
                            required fullWidth />
                    </Box>
                    <Button
                        id="passwordreset-continue-button"
                        type="submit" fullWidth variant="contained"
                    >
                        Continue
                    </Button>
                    <Typography variant="body1" pt={1}>
                        <Link id="passwordreset-returntologin-link" href="#" onClick={handleReturnToLogin}>Return to login</Link>
                    </Typography>
                </Stack>
            </Box>
            <Backdrop open={loginState.loading} className="loading">
                <CircularProgress />
            </Backdrop>
        </React.Fragment>
    );
}
