import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams, createSearchParams } from "react-router-dom";
import {
    Button, TextField, Typography, Link, Box, Backdrop, Stack, CircularProgress
} from '@mui/material';
import ReCAPTCHA from 'reaptcha';
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { initError, loginInit, sendPasswordResetLink, spaReset, spaResetErrorMessages } from "../../app/loginSlice"
import { LoginStage, LoginUserNameOrEmailModel } from "../../interfaces"
import ErrorsBox from '../errorsBox';
import { parseReturnUrl } from '../../functions';
import { GOOGLE_RECAPTCHA_SITEKEY } from "../../constants";

export default function ForgotPassword() {
    const [searchParams] = useSearchParams();
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [reCaptchaToken, setReCaptchaToken] = useState<string | null>(null);
    const loginState = useAppSelector(store => store.login);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (loginState?.loginStage == LoginStage.Initialization) {
            dispatch(loginInit(parseReturnUrl(searchParams.get("returnUrl"))));
        }
        if (loginState?.userData != null) {
            setEmail(loginState?.userData?.userNameOrEmail);
        }
    }, []);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        let hasError: boolean = false;
        if (email.length == 0) {
            setEmailError('Username or email could not be empty.');
            hasError = true;
        }
        else {
            setEmailError('');
        }
        if (loginState.reCaptchaEnabled) {
            if (reCaptchaToken == null) {
                dispatch(initError("Please solve reCAPTCHA puzzle."));
                hasError = true;
            }
            else {
                dispatch(spaResetErrorMessages());
            }
        }
        if (hasError) {
            return;
        }

        const body: LoginUserNameOrEmailModel = {
            userNameOrEmail: email,
            reCaptchaToken: reCaptchaToken
        };
        const dispatchResult = await dispatch(sendPasswordResetLink(body));
        if (dispatchResult.meta.requestStatus == "fulfilled") {
            navigate({
                pathname: '/password/link/sent',
                search: `?${createSearchParams({ returnUrl: parseReturnUrl(searchParams.get("returnUrl")) })}`
            });
        }
    };

    const handleReturnToLogin = () => {
        dispatch(spaReset());
        navigate({
            pathname: '/',
            search: `?${createSearchParams({ returnUrl: parseReturnUrl(searchParams.get("returnUrl")) })}`
        });
    };

    return (
        <React.Fragment>
            <Box component="form" onSubmit={handleSubmit} width={1} noValidate>
                <Stack spacing={1.5}>
                    <Typography variant="h4" color="primary">Reset your account password</Typography>
                    <Typography variant="body1">
                        Enter the username or email address associated with your account
                    </Typography>
                    <Box>
                        <ErrorsBox errors={loginState.messages} />
                        <TextField
                            inputProps={{ style: { height: 12 } }}
                            margin="normal" id="email" name="email" label="Enter username or email" autoComplete="email"
                            onChange={e => { setEmail(e.target.value) }}
                            value={email}
                            error={emailError.length > 0}
                            helperText={emailError}
                            required fullWidth autoFocus />
                        {loginState.reCaptchaEnabled &&
                        <Box display="flex" flexDirection="row" justifyContent="center" pl="1px" pt={1}>
                            <ReCAPTCHA sitekey={GOOGLE_RECAPTCHA_SITEKEY}
                                onVerify={(token) => { setReCaptchaToken(token); }}
                                onExpire={() => { setReCaptchaToken(null); }}
                                hl="en" />
                        </Box>
                        }
                        <Button
                            id="forgotpassword-continue-button"
                            type="submit" fullWidth variant="contained" sx={{ mt: 2 }}
                        >
                            Continue
                        </Button>
                    </Box>
                    <Typography variant="body1" pt={1}>
                        <Link id="forgotpassword-returntologin-link" href="#" onClick={handleReturnToLogin}>Return to login</Link>
                    </Typography>
                </Stack>
            </Box>
            <Backdrop open={loginState.loading} className="loading">
                <CircularProgress />
            </Backdrop>
        </React.Fragment>
    );
}
