import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams, createSearchParams } from "react-router-dom";
import {
  Button, TextField, Typography, Box, Backdrop, Stack, CircularProgress, Link
} from '@mui/material';
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import {
  initError, initPassword, loginInit, findUser, passwordResetInit, spaReset
} from "../../app/loginSlice"
import {
  ApiViewEntityResponse, LoginStageResultModel, LoginContextModel, LoginStage
} from '../../interfaces';
import ErrorsBox from '../errorsBox';
import { parseReturnUrl } from '../../functions'

export default function User() {
  const [searchParams] = useSearchParams();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [password, setPassword] = useState('');
  const loginState = useAppSelector(store => store.login);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const initApplication = async () => {
      const dispatchResult = await dispatch(loginInit(parseReturnUrl(searchParams.get("returnUrl"))))
      if (dispatchResult.meta.requestStatus == "fulfilled") {
        const loginContext = dispatchResult.payload as ApiViewEntityResponse<LoginStageResultModel<LoginContextModel>>;
        if (loginContext.data.metadata.user != null && loginContext.data.metadata.user.isAuthenticated) {
          navigate({
            pathname: '/client',
            search: `?${createSearchParams({ returnUrl: parseReturnUrl(searchParams.get("returnUrl")) })}`
          });
        }
        else if (loginContext.data.stage == LoginStage.Mfa) {
          navigate({
            pathname: '/mfa/providers',
            search: `?${createSearchParams({ returnUrl: parseReturnUrl(searchParams.get("returnUrl")) })}`
          });
        }
        else if (loginContext.data.stage == LoginStage.ForcePasswordReset) {
          navigate({
            pathname: '/password/change',
            search: `?${createSearchParams({ returnUrl: parseReturnUrl(searchParams.get("returnUrl")) })}`
          });
        }
        else if (loginContext.data.stage == LoginStage.FillUserProfile) {
          navigate({
              pathname: '/signin/profile',
              search: `?${createSearchParams({ returnUrl: parseReturnUrl(searchParams.get("returnUrl")) })}`
          });
        }
      }
    }
    if (loginState?.loginStage == LoginStage.Initialization) {
      initApplication().catch(console.error);
      const error = searchParams.get("e");
      if (error) {
        dispatch(initError(error));
      }
    }
    else {
      dispatch(spaReset());
    }
  }, []);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (email.length == 0) {
      setEmailError('Email or username could not be empty.');
      return;
    }
    else {
        setEmailError('');
    }

    const dispatchResult = await dispatch(findUser(email));
    if (dispatchResult.meta.requestStatus == "fulfilled") {
      if (password) {
        dispatch(initPassword(password));
      }
      navigate({
        pathname: '/signin',
        search: `?${createSearchParams({ returnUrl: parseReturnUrl(searchParams.get("returnUrl")) })}`
      });
    }
  };

  const handleForgotPassword = () => {
    dispatch(passwordResetInit());
    navigate({
      pathname: '/password/forgot',
      search: `?${createSearchParams({ returnUrl: parseReturnUrl(searchParams.get("returnUrl")) })}`
    });
  };

  return (
    <React.Fragment>
      <Box component="form" onSubmit={handleSubmit} width={1} noValidate>
        <Stack spacing={1.5}>
          <Box>
            <ErrorsBox errors={loginState.messages} />
            <TextField
              inputProps={{ style: { height: 12 } }}
              margin="normal" id="email" name="email" label="Enter email or username" autoComplete="email"
              onChange={e => { setEmail(e.target.value) }}
              error={emailError.length > 0}
              helperText={emailError}
              required fullWidth autoFocus />
          </Box>
          <Button type="submit" id="user-continue-button" fullWidth variant="contained">
              Continue
          </Button>
          <Typography variant="body1" pt={1}>
            <Link id="user-forgotpassword-link" href="#" onClick={handleForgotPassword}>Forgot password?</Link>
          </Typography>
        </Stack>
        <TextField id="password" name="password" type="password" size='small'
          sx={{ visibility: "hidden", width: 0, height: 0, position: "absolute" }} autoComplete="current-password"
          onChange={e => { setPassword(e.target.value) }} />
      </Box>
      <Backdrop open={loginState.loading} className="loading">
        <CircularProgress />
      </Backdrop>
    </React.Fragment>
  );
}
