import apiService from './apiService';
import {
    ApiViewResponse, ApiViewEntityResponse, LoginStageResultModel,
    LoginUserDataModel, LoginResultModel, LoginUserCredentialsModel,
    LoginMfaCodeModel, MfaMethodModel, MfaMethod, LoginReturnUrlModel,
    LoginContextModel, LoginMfaCodeResultModel, LoginUserNameOrEmailModel,
    UserPasswordResetTokenRequestModel, ChangePasswordRequestModel, ApplicationModel,
    LoginSigninProfileModel, NewPasswordRequestModel
} from '../interfaces';
import { HTTP_METHOD, IDENTITY_SERVER_URL } from "../constants";
import { CountryModel, UserAddressModel, UserContactDataModel, UserModel, UserPersonalDetailsModel } from '../interfacesProfile';

export default {
    async loginInit(returnUrl: string): Promise<ApiViewEntityResponse<LoginStageResultModel<LoginContextModel>>> {
        const body: LoginReturnUrlModel = {
            returnUrl: returnUrl
        };
        let response = await apiService.fetch(`${IDENTITY_SERVER_URL}/v1/view`, {
            method: HTTP_METHOD.POST,
            credentials: "include",
            body: JSON.stringify(body)
        });
        const result: ApiViewEntityResponse<LoginStageResultModel<LoginContextModel>> = await response.json()
        return result;
    },

    async findUser(userNameOrEmail: string): Promise<ApiViewEntityResponse<LoginStageResultModel<LoginUserDataModel>>> {
        let response = await apiService.fetch(`${IDENTITY_SERVER_URL}/v1/view/login?userNameOrEmail=${userNameOrEmail}`, {
            method: HTTP_METHOD.GET,
            credentials: "include",
        });
        const result: ApiViewEntityResponse<LoginStageResultModel<LoginUserDataModel>> = await response.json()
        return result;
    },

    async loginUser(body: LoginUserCredentialsModel): Promise<ApiViewEntityResponse<LoginStageResultModel<LoginResultModel>>> {
        let response = await apiService.fetch(`${IDENTITY_SERVER_URL}/v1/view/login`, {
            method: HTTP_METHOD.POST,
            credentials: "include",
            body: JSON.stringify(body)
        });
        const result: ApiViewEntityResponse<LoginStageResultModel<LoginResultModel>> = await response.json()
        return result;
    },

    async createMfaCode(mfaMethod: MfaMethod): Promise<ApiViewEntityResponse<LoginStageResultModel<MfaMethodModel>>> {
        let response = await apiService.fetch(`${IDENTITY_SERVER_URL}/v1/view/mfa?method=${mfaMethod}`, {
            method: HTTP_METHOD.GET,
            credentials: "include"
        });
        const result: ApiViewEntityResponse<LoginStageResultModel<MfaMethodModel>> = await response.json()
        return result;
    },

    async verifyMfaCode(body: LoginMfaCodeModel): Promise<ApiViewEntityResponse<LoginStageResultModel<LoginMfaCodeResultModel>>> {
        let response = await apiService.fetch(`${IDENTITY_SERVER_URL}/v1/view/mfa`, {
            method: HTTP_METHOD.POST,
            credentials: "include",
            body: JSON.stringify(body)
        });
        const result: ApiViewEntityResponse<LoginStageResultModel<LoginMfaCodeResultModel>> = await response.json()
        return result;
    },

    async logoutUser(): Promise<ApiViewResponse> {
        let response = await apiService.fetch(`${IDENTITY_SERVER_URL}/v1/view/user/logout`, {
            method: HTTP_METHOD.POST,
            credentials: "include"
        });
        const result: ApiViewResponse = await response.json()
        return result;
    },

    async sendPasswordResetLink(body: LoginUserNameOrEmailModel): Promise<ApiViewEntityResponse<LoginStageResultModel<object>>> {
        let response = await apiService.fetch(`${IDENTITY_SERVER_URL}/v1/view/passwordReset/link`, {
            method: HTTP_METHOD.POST,
            credentials: "include",
            body: JSON.stringify(body)
        });
        const result: ApiViewEntityResponse<LoginStageResultModel<object>> = await response.json()
        return result;
    },

    async resetUserPassword(externalId: string, body: UserPasswordResetTokenRequestModel): Promise<ApiViewResponse> {
        let response = await apiService.fetch(`${IDENTITY_SERVER_URL}/v1/view/password/reset?externalId=${externalId}`, {
            method: HTTP_METHOD.POST,
            credentials: "include",
            body: JSON.stringify(body)
        });
        const result: ApiViewResponse = await response.json()
        return result;
    },

    async changeUserPassword(body: ChangePasswordRequestModel): Promise<ApiViewEntityResponse<LoginStageResultModel<LoginResultModel>>> {
        let response = await apiService.fetch(`${IDENTITY_SERVER_URL}/v1/view/password/change`, {
            method: HTTP_METHOD.POST,
            credentials: "include",
            body: JSON.stringify(body)
        });
        const result: ApiViewEntityResponse<LoginStageResultModel<LoginResultModel>> = await response.json()
        return result;
    },

    async updateSigninProfile(body: LoginSigninProfileModel): Promise<ApiViewEntityResponse<LoginStageResultModel<LoginResultModel>>> {
        let response = await apiService.fetch(`${IDENTITY_SERVER_URL}/v1/view/signin/profile`, {
            method: HTTP_METHOD.PUT,
            credentials: "include",
            body: JSON.stringify(body)
        });
        const result: ApiViewEntityResponse<LoginStageResultModel<LoginResultModel>> = await response.json()
        return result;
    },

    async getUserApplications(): Promise<ApiViewEntityResponse<LoginStageResultModel<ApplicationModel[]>>> {
        let response = await apiService.fetch(`${IDENTITY_SERVER_URL}/v1/view/user/applications`, {
            method: HTTP_METHOD.GET,
            credentials: "include"
        });
        const result: ApiViewEntityResponse<LoginStageResultModel<ApplicationModel[]>> = await response.json()
        return result;
    },

    async getCountries(): Promise<ApiViewEntityResponse<CountryModel[]>> {
        let response = await apiService.fetch(`${IDENTITY_SERVER_URL}/v1/countries`, {
            method: HTTP_METHOD.GET
        });
        const result: ApiViewEntityResponse<CountryModel[]> = await response.json()
        return result;
    },

    async getApplications(): Promise<ApiViewEntityResponse<ApplicationModel[]>> {
        let response = await apiService.fetch(`${IDENTITY_SERVER_URL}/v1/applications`, {
            method: HTTP_METHOD.GET
        });
        const result: ApiViewEntityResponse<ApplicationModel[]> = await response.json()
        return result;
    },

    async getUser(): Promise<ApiViewEntityResponse<UserModel>> {
        let response = await apiService.fetch(`${IDENTITY_SERVER_URL}/v1/view/profile`, {
            method: HTTP_METHOD.GET,
            credentials: "include"
        });
        const result: ApiViewEntityResponse<UserModel> = await response.json()
        return result;
    },

    async getCurrentUserApplications(): Promise<ApiViewEntityResponse<ApplicationModel[]>> {
        let response = await apiService.fetch(`${IDENTITY_SERVER_URL}/v1/view/profile/applications`, {
            method: HTTP_METHOD.GET,
            credentials: "include"
        });
        const result: ApiViewEntityResponse<ApplicationModel[]> = await response.json()
        return result;
    },

    async updateUserPersonalDetails(personalDetails: UserPersonalDetailsModel): Promise<ApiViewEntityResponse<UserModel>> {
        let response = await apiService.fetch(`${IDENTITY_SERVER_URL}/v1/view/profile/personal-details`, {
            method: HTTP_METHOD.PUT,
            credentials: "include",
            body: JSON.stringify(personalDetails)
        });
        const result: ApiViewEntityResponse<UserModel> = await response.json()
        return result;
    },

    async createUserAddress(userAddress: UserAddressModel): Promise<ApiViewEntityResponse<UserModel>> {
        let response = await apiService.fetch(`${IDENTITY_SERVER_URL}/v1/view/profile/addresses`, {
            method: HTTP_METHOD.POST,
            credentials: "include",
            body: JSON.stringify(userAddress)
        });
        const result: ApiViewEntityResponse<UserModel> = await response.json()
        return result;
    },

    async updateUserAddress(userAddressId: number, userAddress: UserAddressModel): Promise<ApiViewEntityResponse<UserModel>> {
        let response = await apiService.fetch(`${IDENTITY_SERVER_URL}/v1/view/profile/addresses/${userAddressId}`, {
            method: HTTP_METHOD.PUT,
            credentials: "include",
            body: JSON.stringify(userAddress)
        });
        const result: ApiViewEntityResponse<UserModel> = await response.json()
        return result;
    },

    async createUserContact(contact: UserContactDataModel): Promise<ApiViewEntityResponse<UserModel>> {
        let response = await apiService.fetch(`${IDENTITY_SERVER_URL}/v1/view/profile/contacts`, {
            method: HTTP_METHOD.POST,
            credentials: "include",
            body: JSON.stringify(contact)
        });
        const result: ApiViewEntityResponse<UserModel> = await response.json()
        return result;
    },

    async updateUserContact(contactId: number, contact: UserContactDataModel): Promise<ApiViewEntityResponse<UserModel>> {
        let response = await apiService.fetch(`${IDENTITY_SERVER_URL}/v1/view/profile/contacts/${contactId}`, {
            method: HTTP_METHOD.PUT,
            credentials: "include",
            body: JSON.stringify(contact)
        });
        const result: ApiViewEntityResponse<UserModel> = await response.json()
        return result;
    },

    async deleteUserContact(contactId: number): Promise<ApiViewEntityResponse<UserModel>> {
        let response = await apiService.fetch(`${IDENTITY_SERVER_URL}/v1/view/profile/contacts/${contactId}`, {
            method: HTTP_METHOD.DELETE,
            credentials: "include"
        });
        const result: ApiViewEntityResponse<UserModel> = await response.json()
        return result;
    },

    async updateUserName(userName: string): Promise<ApiViewEntityResponse<UserModel>> {
        let response = await apiService.fetch(`${IDENTITY_SERVER_URL}/v1/view/profile/userName`, {
            method: HTTP_METHOD.PUT,
            credentials: "include",
            body: JSON.stringify({ userName: userName })
        });
        const result: ApiViewEntityResponse<UserModel> = await response.json()
        return result;
    },

    async changeProfilePassword(request: NewPasswordRequestModel): Promise<ApiViewEntityResponse<UserModel>> {
        let response = await apiService.fetch(`${IDENTITY_SERVER_URL}/v1/view/profile/password`, {
            method: HTTP_METHOD.PUT,
            credentials: "include",
            body: JSON.stringify(request)
        });
        const result: ApiViewEntityResponse<UserModel> = await response.json()
        return result;
    }
}
